.App{
    width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
}
@font-face {
  font-family: "OpenSans";
  src: url("../../../public/Open_Sans/OpenSans-VariableFont_wdth\,wght.ttf") format("opentype") ;
  font-display: swap;
}

* {
  font-family: "OpenSans";
}
