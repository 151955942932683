* {
    box-sizing: border-box;
    outline: none;
    margin: 0;
    padding: 0;
}
html, body, #root{
    height: 100%;
  }
ul {
    list-style: none;
}

a:link {
    text-decoration: none;
}
