.carou-actus {
  margin: 1em 0;

  * {
    color: white !important;
    text-shadow: #0008 1px 1px 10px,#0008 1px -1px 10px,#0008 -1px -1px 10px,#0008 -1px 1px 10px;
  }

  .carousel-item>a {
    background: #88FF0022;
    margin: 0 10%;
    width: 80%;
    height: min(30em,50vh);
  }

  img {
    max-height: 100%;
    max-width: 80%;
    object-fit: contain;
  }
}