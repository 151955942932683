td>*,
th>* {
  display: block;
  text-align: center;
  margin: 0;
  height: 100%;
  width: 100%;
  color: black !important;
}

.compact {
  cursor: pointer;
  max-height: 2em;

  >td {
    padding: 0.1em !important;
  }

  img {
    max-height: 2em;
  }
}

main {
  height: 100%;
  z-index: -10;



  .sidebar {
    min-width: 12em;
    max-height: 100vh;
    height: min-content;
    overflow-y: scroll;
    margin-right: -24px;
  }

  .admin-title-bar {
    margin-bottom: -24px;
  }

  .main-box {
    min-width: calc(100vw - 12em + 24px);
    max-width: calc(100vw - 12em + 24px);
  }
}

.medium-image {
  max-width: 10em;
  max-height: 10em
}

.admin-label-input {
  margin: 0.5em;

  label:not(:has(+ textarea)) {
    width: 9em;
  }
  
  input {
    width: calc(100% - 9em);
    padding: 0.2em;
  }

  select {
    width: calc(100% - 9em);
    height: 2em;
    padding: 0.2em;
  }

  select.mini {
    width: 1.4em;
    height: 2em;
    padding: 0.2em;
  }

  input.with-options {
    width: calc(100% - 10.4em);
  }

  textarea {
    margin-top: 0.3em;
    padding: 0.2em;
    width: 100%;
    min-height: 7em;
    &.mini {
      min-height: 1em;
    }
  }
}

.admin-label-input-newphoto {
  margin: 0.5em;

  input {
    width: 100%;
    padding: 0.2em;
  }
}

.admin-label-input-photo {
  width: 100%;

  label {
    width: 100%;
  }

  input {
    width: calc(100% - 0.6em);
    margin: 0.2em;
    padding: 0.2em;
  }
}

.over-y {
  height: 5em;
  overflow-y: scroll;
}

.rondelle-border {
  display: inline-block;
  padding: 0;
  border: solid;
  border-width: 18px;
  border-image: url('../../../public/bordures/rondelle.svg') 20 50;
  background-color: #00000000 !important;

  >* {
    padding: 0 0.5em;
    background-color: #ebd7cf !important;
    font-weight: 600;
    color: #FFF;
    text-shadow: #000 1px 1px 1px, #000 1px -1px 1px, #000 -1px -1px 1px, #000 -1px 1px 1px;
  }

  :hover>* {
    text-shadow: #000 1px 1px 3px, #000 1px -1px 3px, #000 -1px -1px 3px, #000 -1px 1px 3px;
  }
}

.icon-rondelle {
  transition: transform 0.8s;
}


.icon-rondelle:hover {
  transform: rotate3d(-0.2, -1, -0.5, -20deg);
}

.icon-medium {
  height: 3em;
}

.icon-table {
  height: 1em;
}



.bambou-border-large {
  border: solid;
  border-width: 24px;
  border-image: url('../../../public/bordures/vert-c-large.svg') 30 round;

}

.bambou-border-b-large {
  border: solid;
  border-width: 0 0 24px 0;
  border-image: url('../../../public/bordures/vert-c-large.svg') 30 round;

}

.bambou-border-x-large {
  border: solid;
  border-width: 0 24px;
  border-image: url('../../../public/bordures/vert-c-large.svg') 30 round;
}

.bambou-border-short {
  border: solid;
  border-width: 16px;
  border-image: url('../../../public/bordures/vert-c-short.svg') 20 round;
}

.bambou-border-x-short {
  border: solid;
  border-width: 0 16px;
  border-image: url('../../../public/bordures/vert-c-short.svg') 20 round;
}


.col-small {
  width: 6em;
}

.col-medium {
  width: 10em;
}

.col-button {
  width: 2em;
}

.table.table-striped:not(.table-hover) {
  th,th * {
    text-align: left !important;
  }

  tr {
    margin: 0;
    padding: 0;
  }

  tr>td,
  tr>th {
    margin: 0;
    padding: 0;
    height: 1.5em;
  }

  tr>td:has(hr) {
    height: 0.5em;
  }

  tr>td>hr {
    margin: 0.5em 0;
  }
}

.barre {
  text-decoration: line-through !important;
}