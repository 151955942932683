:root {
  --var-hl-ref-width : 40vw;
  @media (max-width: 767.98px) {
    --var-hl-ref-width : 50vw;
  }
}

.gallery-photo{
  display: block;
  overflow: hidden;
  position: relative;
  img {
    display: block;
  }
  img:last-child {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.high-light-box-a {
  width: calc(var(--var-hl-ref-width) * 2);
  margin: 2em auto 0;
  background: #88FF0022;
}

.high-light-container {
  width: calc(var(--var-hl-ref-width) * 2);
  margin: 0 auto;
}

.high-light-box-b {
  width: var(--var-hl-ref-width);
  @media (max-width: 767.98px) {
    width: calc(var(--var-hl-ref-width) * 2);
  }
  margin: 2em 2em 0 0;
  background: #b3ff0022;
}

.high-light-box-c {
  width: var(--var-hl-ref-width);
  @media (max-width: 767.98px) {
    width: calc(var(--var-hl-ref-width) * 2);
  }
  margin: 2em 2em 0 0;
  background: #ffd90022;
}

.high-light-box-d {
  width: var(--var-hl-ref-width);
  @media (max-width: 767.98px) {
    width: calc(var(--var-hl-ref-width) * 2);
  }
  margin: 2em 2em 0 0;
  background: #ff880022;
}

.high-light-box-e {
  width: var(--var-hl-ref-width);
  @media (max-width: 767.98px) {
    width: calc(var(--var-hl-ref-width) * 2);
  }
  margin: 2em auto 0;
  background: #ff220022;
}

.high-light-box-f {
  width: calc(var(--var-hl-ref-width) * 2);
  margin: 2em auto 0;
  background: #ffd90022;
}

.high-light-box-g {
  width: calc(var(--var-hl-ref-width) * 2);
  margin: 2em auto 0;
  background: #b3ff0022;
}

.half-box-one {
  color: black;
  display: block;
  text-align: left;
  width: var(--var-hl-ref-width);
  @media (max-width: 767.98px) {
    width: calc(var(--var-hl-ref-width) * 2);
  }
  margin: 2em auto;
  background: #88FF0022;
}

.half-box-two {
  color: black;
  display: block;
  text-align: right;
  width: var(--var-hl-ref-width);
  @media (max-width: 767.98px) {
    width: calc(var(--var-hl-ref-width) * 2);
  }
  margin: 2em auto;
  background: #88FF0022;
}

.carou-photos {

  .carousel-item {
    .carousel-item-center {
      display: flex;
      height: min(37em, 50vh);
    }
  }

  img {
    max-height: 100%;
    max-width: 80%;
    object-fit: contain;
  }
}

.carouselle-programmes {
  width: calc(100vw - 15px);
  overflow-x: hidden;

  .fleche {
    position: relative;
    width: 0;
    height: 100%;
    .icon-rondelle {
      position: absolute;
      top : calc(220px - min(6vw, 34px));
      max-width: 12vw;
      z-index: 1;
      @media (max-width: 767.98px) {
        top : calc(120px - min(6vw, 34px));
      }
      transition: transform 0.5s;
      &:hover {
        transform: scale(1.1) rotate3d(0,1,0.2,30deg);
        &:active {
          transform: scale(1.05) rotate3d(0,1,0,30deg);
        }
      }
    }
  }
  .gauche {
    .icon-rondelle {
      left: 4px;
      @media (max-width: 767.98px) {
        left: 0px;
      }
    }
  }
  .droite {
    .icon-rondelle {
      right: 4px;
      @media (max-width: 767.98px) {
        right: 0px;
      }
    }
  }
  .ronde {
    position: relative;
    width: 100%;
    height: max-content;
    >* {
      height: max-content;
      position: absolute;
      transition: left 1s;
    }
  }
}


.item-card {
  p {
    padding: 0.3em;
    margin: 0em;
    text-align: center;
  }
  p:not(.text-decoration-line-through){
    font-size: 1.2em;
    font-weight: bold;
  }
  p.text-decoration-line-through{
    opacity: 0.5;
  }
  .item-card-data{
    background: #88FF0011;
  }
  &.programme {
    --var-item-width : min(20rem, 95vw);
    >div {
      width: var(--var-item-width);
      border: solid;
      border-width: 24px;
      border-image: url('../../../public/bordures/vert-f-large.svg') 30 round;
      

      >div {
        background: #f3e0d8;
        margin: -7px;
      }
      @media (max-width: 767.98px) {
        .gallery-photo{
          width: calc( var(--var-item-width) / 2 ) !important;
          height:calc( var(--var-item-width) / 2 ) !important;
          min-width: calc( var(--var-item-width) / 2 ) !important;
          min-height:calc( var(--var-item-width) / 2 ) !important;
        }
      }
    }
  }
  &.module {
    --var-item-width : min(18rem, 95vw);
    >div {
      width: var(--var-item-width);
      border: solid;
      border-width: 24px;
      border-image: url('../../../public/bordures/vert-c-large.svg') 30 round;
      

      >div {
        background: #f3e0d8;
        margin: -7px;
      }
      @media (max-width: 767.98px) {
        .gallery-photo{
          width: calc( var(--var-item-width) / 2 ) !important;
          height:calc( var(--var-item-width) / 2 ) !important;
          min-width: calc( var(--var-item-width) / 2 ) !important;
          min-height:calc( var(--var-item-width) / 2 ) !important;
        }
      }
    }
  }
  &.actualite {
    --var-item-width : min(22rem, 95vw);
    >div {
      width: var(--var-item-width);
      border: solid;
      border-width: 24px;
      border-image: url('../../../public/bordures/jaune-f-large.svg') 30 round;
      

      >div {
        background: #f3e0d8;
        width: calc(100% + 14px) !important;
        margin: -7px;
      }
      @media (max-width: 767.98px) {
        >div {
          background: #f3e0d8;
          width: calc( var(--var-item-width) / 2 - 20px) !important;
          margin: -7px 0px -7px -7px;
        }
        .gallery-photo{
          margin: -7px -7px -7px 0px;
          width: calc( var(--var-item-width) / 2 ) !important;
          height:calc( var(--var-item-width) / 2 ) !important;
          min-width: calc( var(--var-item-width) / 2 ) !important;
          min-height:calc( var(--var-item-width) / 2 ) !important;
        }
      }
    }
  }
}

.bouton-actu {
  background: none;
  border: solid;
  border-width: 0 0 24px 0;
  border-image: url('../../../public/bordures/jaune-f-large.svg') 30 round;
}